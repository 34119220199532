import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  AdminGetAllLicensesRequestDto,
  AdminService,
  DeleteLicenseRequestDto,
  LicenseDto,
  LicenseSort,
} from '@gentext/api-client';
import { LoggingService } from '@gentext/logging';
import { merge, startWith } from 'rxjs';

@Component({
  selector: 'finance-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css'],
  standalone: false,
})
export class AdminUsersComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: MatTableDataSource<LicenseDto> = new MatTableDataSource();
  apiBaseUrl = `https://${process.env['NX_API_HOST']}`;
  loading = false;
  totalCount = 0;
  filter = '';

  get displayedColumns(): string[] {
    return [
      'emailAddress',
      'userDisplayName',
      'words',
      'dateTime',
      'planId',
      'delete',
    ];
  }

  constructor(
    private adminService: AdminService,
    private cdr: ChangeDetectorRef,
    private logging: LoggingService,
  ) {}

  ngAfterViewInit() {
    console.log('It initialized?');
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith({}))
      .subscribe(() => {
        this.getData();
        this.cdr.detectChanges();
      });
  }
  deleteLicense(license: LicenseDto) {
    if (
      !confirm(
        'Are you sure you want to delete this license and associated usage records?',
      )
    ) {
      return;
    }
    if (!license.emailAddress) {
      alert('Email address is required to delete a license.');
      return;
    }
    const request: DeleteLicenseRequestDto = {
      emailAddress: license.emailAddress,
    };

    this.adminService.adminDeleteLicenseDelete(request).subscribe(() => {
      this.getData();
    });
  }

  applyFilter() {
    this.getData();
  }
  private getData() {
    console.log('GetData');
    const sort: LicenseSort | undefined =
      this.sort.active === 'wordsLeft'
        ? LicenseSort.Words
        : this.sort.active === 'emailAddress'
          ? LicenseSort.Email
          : this.sort.active === 'planId'
            ? LicenseSort.PlanId
            : undefined;
    const request: AdminGetAllLicensesRequestDto = {
      pageNumber: this.paginator.pageIndex,
      perPage: this.paginator.pageSize,
      sort,
      sortDesc: this.sort.direction === 'desc',
      filter: this.filter,
    };
    this.loading = true;
    this.adminService.adminGetAllLicensesPost(request).subscribe((result) => {
      this.logging.trace({
        message: `Here's the result from admin service: ${result}`,
      });
      this.dataSource = new MatTableDataSource(result.licenses);
      this.totalCount = result.totalCount;
      this.loading = false;
    });
  }
}
