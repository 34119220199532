import { Injectable } from '@angular/core';
import { BillingService } from '@gentext/api-client';
import { LoggingService } from '@gentext/logging';

@Injectable({ providedIn: 'root' })
export class BillingUiService {
  constructor(
    private billingService: BillingService,
    private logging: LoggingService,
  ) {}
  managePlan(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.billingService
        .billingGetPortalUrlPost({
          returnUrl: `${window.location.href}`,
        })
        .subscribe({
          next: (res) => {
            window.location.href = res.url;
            resolve();
          },
          error: (err) => {
            this.logging.exception(err);
            reject();
          },
        });
    });
  }
}
